import {
  type AIMessage,
  type Assistant,
  Client,
  type HumanMessage,
  type Message,
  type ToolMessage,
} from '@langchain/langgraph-sdk';
import { useStream } from '@langchain/langgraph-sdk/react';
import { useLoaderData } from '@remix-run/react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useUserAnalytics } from '../analytics/user';
import { AdminAccess } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { ArrowDownIcon, ArrowUpIcon } from '../components/icons/Arrows';
import { Loading } from '../components/Loading';
import { MarkdownBody } from '../components/MarkdownEditor';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import config from '../config';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';

const apiUrl = `${config.app.baseUrl}/graph`;

const createClient = () => {
  return new Client({
    apiUrl,
  });
};

type MessageContentImageUrl = Exclude<
  Exclude<Message['content'][number], string>,
  { type: 'text' }
>;

type TooCall = NonNullable<AIMessage['tool_calls']>[number];

export async function clientLoader() {
  const client = createClient();
  const assistants = await client.assistants.search({ graphId: 'demo' });
  if (assistants.length === 0) {
    throw new Response('Not Found', { status: 404 });
  }
  return { assistant: assistants[0] };
}

function StringContent(props: { content: string }) {
  return <MarkdownBody body={props.content} />;
}

function ImageContent(props: { content: MessageContentImageUrl['image_url'] }) {
  if (typeof props.content === 'string') {
    return <img src={props.content} alt='' width={100} />;
  }
  return <img src={props.content.url} alt='' width={100} />;
}

function MessageConentView(props: { content: Message['content'] }) {
  const { content } = props;
  if (typeof content === 'string') {
    return <StringContent content={content} />;
  } else {
    return (
      <div>
        {content.map((entry) => {
          return entry.type === 'text' ? (
            <StringContent content={entry.text} />
          ) : (
            <ImageContent content={entry.image_url} />
          );
        })}
      </div>
    );
  }
}

function ThreadUserMessage(props: { message: HumanMessage }) {
  return (
    <div className='self-end py-1 px-4 bg-light-gray rounded-xl'>
      <MessageConentView content={props.message.content} />
    </div>
  );
}

function TooCalledCard(props: { toolCall: TooCall }) {
  const { toolCall } = props;
  const [expanded, setExpanded] = useState(false);
  return (
    <div className='py-4 px-4 bg-lp-black-003 rounded-xl'>
      <div className='flex items-center justify-between'>
        <div>
          <span>Tool used:</span>
          <span className='font-bold ml-2'>{toolCall.name}</span>
        </div>
        <button
          type='button'
          className='btn'
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </button>
      </div>
      <div
        className={`mt-2 text-xs text-secondary ${
          expanded ? 'block' : 'hidden'
        }`}
      >
        {JSON.stringify(toolCall.args)}
      </div>
    </div>
  );
}

function ThreadAssistantMessage(props: {
  message: AIMessage;
  showLoading: boolean;
}) {
  const { message } = props;
  return (
    <div className='flex flex-col gap-2'>
      {props.showLoading && (
        <div className='absolute transform -translate-x-full'>
          <Loading imgClassName='w-5 h-5' text='' />
        </div>
      )}
      <MessageConentView content={props.message.content} />
      {message.tool_calls?.map((toolCall) => (
        <TooCalledCard key={toolCall.id} toolCall={toolCall} />
      ))}
    </div>
  );
}

type SearchResult = {
  title: string;
  url: string;
  content: string;
  score: number;
};

function SearhResultView(props: { result: SearchResult }) {
  const { result } = props;
  const [expanded, setExpanded] = useState(false);
  return (
    <div key={result.url}>
      <div className='flex items-center justify-between'>
        <a
          className='text-primary underline'
          target='_blank'
          href={result.url}
          rel='noreferrer'
        >
          {result.title}
        </a>
        <button
          type='button'
          className='btn'
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </button>
      </div>
      <div
        className={`text-xs text-secondary ${expanded ? 'block' : 'hidden'}`}
      >
        {result.content}
      </div>
    </div>
  );
}

function SearchToolMessage(props: { message: ToolMessage }) {
  const { message } = props;
  const results = useMemo(() => {
    if (typeof message.content !== 'string') {
      return [];
    }
    return JSON.parse(message.content) as SearchResult[];
  }, [message.content]);

  return (
    <div className='p-4 bg-dark-gray rounded-xl'>
      <div className='font-bold'>Search Results:</div>
      <div className='flex flex-col gap-2'>
        {results.map((result) => (
          <SearhResultView key={result.url} result={result} />
        ))}
      </div>
    </div>
  );
}

function ThreadToolMessage(props: { message: ToolMessage }) {
  const { message } = props;
  if (message.name === 'tavily_search_results_json') {
    return <SearchToolMessage message={message} />;
  }
  return <MessageConentView content={`Running Tool: ${message.name}`} />;
}

function ThreadMessage(props: { message: Message; showLoading: boolean }) {
  const { message } = props;
  if (message.type === 'human') {
    return <ThreadUserMessage message={message} />;
  } else if (message.type === 'ai') {
    return (
      <ThreadAssistantMessage
        message={message}
        showLoading={props.showLoading}
      />
    );
  } else if (message.type === 'tool') {
    return <ThreadToolMessage message={message} />;
  }
  return <></>;
}

function Main(props: { assistant: Assistant }) {
  const { assistant } = props;
  const thread = useStream({
    apiUrl,
    assistantId: assistant.assistant_id,
  });
  const ref = useRef<HTMLDivElement>(null);

  const lastMessage = thread.messages[thread.messages.length - 1];

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [thread.messages.length, lastMessage?.content?.length, thread.isLoading]);

  return (
    <div className='flex flex-col flex-1 text-white w-200 self-center overflow-y-auto gap-4'>
      <div ref={ref} className='flex-1 flex flex-col gap-5 overflow-y-auto'>
        {thread.messages.map((message, idx) => (
          <ThreadMessage
            key={message.id}
            message={message}
            showLoading={
              thread.isLoading &&
              message.type === 'ai' &&
              idx === thread.messages.length - 1
            }
          />
        ))}
        {thread.messages.length === 0 && (
          <div className='flex-1 flex items-center justify-center'>
            How can I help you today?
          </div>
        )}
      </div>

      <form
        className='flex items-center gap-5'
        autoComplete='off'
        onSubmit={(e) => {
          e.preventDefault();

          const form = e.target as HTMLFormElement;
          const message = new FormData(form).get('message') as string;

          form.reset();
          thread.submit({
            messages: [{ type: 'human', content: message }],
          });
        }}
      >
        <input className='field mb-0 h-10' type='text' name='message' />

        {thread.isLoading ? (
          <button
            className='btn-primary w-40 h-10'
            key='stop'
            type='button'
            onClick={() => thread.stop()}
          >
            Stop
          </button>
        ) : (
          <button className='btn-primary w-40 h-10' type='submit'>
            Send
          </button>
        )}
      </form>
    </div>
  );
}

export function Component() {
  const { assistant } = useLoaderData<typeof clientLoader>();
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <AdminAccess />,
  ];

  return (
    <ProvidersList providers={providers}>
      <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
        <AdminToolkitNav />
        <Main assistant={assistant as Assistant} />
      </AdminView>
      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
